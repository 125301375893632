import { BigNumber, Contract, ethers } from 'ethers';
//import { useHasPendingApproval, useTransactionAdder } from '../state/transactions/hooks';
import { useCallback, useMemo } from 'react';
import useAllowance from './useAllowance';
import { getDisplayBalance } from 'helpers/tokenUtils';

const APPROVE_AMOUNT = ethers.constants.MaxUint256;
const APPROVE_BASE_AMOUNT = BigNumber.from('1000000000000000000000000');

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApprove(
  token: Contract | null,
  spender: string,
  account: any,
  amount?: BigNumber,
): [ApprovalState, () => Promise<void>] {
  //const pendingApproval = useHasPendingApproval(token.address, spender);
  const currentAllowance = useAllowance(token, spender, account);
  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN;

    // amountToApprove will be defined if currentAllowance is
    console.log(
      'currentAllowance',
      getDisplayBalance({ balance: currentAllowance, decimals: 9 }),
      'amount',
      getDisplayBalance({ balance: amount ?? BigNumber.from(0), decimals: 9 }),
    );
    return currentAllowance.lt(amount ?? APPROVE_BASE_AMOUNT) ? ApprovalState.NOT_APPROVED : ApprovalState.APPROVED;
  }, [currentAllowance]);

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily');
      return;
    }
    if (!account || !token) {
      return;
    }
    try {
      const tx = await token.approve(spender, APPROVE_AMOUNT);
    } catch (e) {
      console.warn(e);
    }
  }, [approvalState, token, spender, account, amount]);

  return [approvalState, approve];
}
