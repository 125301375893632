import React, { FC } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { line119 } from '../ui';

interface IStakingTop {
  tabs?: Array<{
    text: string;
    onSelection: Function;
    selected?: boolean;
  }>;
}

export const StakingTop: FC<IStakingTop> = (props) => {
  const { tabs = [] } = props;
  const isDesktop = useMediaQuery('(min-width:1024px)');

  return (
    <div className="staking-top">
      {tabs.map((element, _) => {
        const isLastElement = _ + 1 === tabs.length;
        if (isDesktop)
          return (
            <React.Fragment key={_}>
              <div
                onClick={() => element.onSelection()}
                className={`staking-1 ${element.selected ? 'oxanium-bold-white-25px' : 'oxanium-normal-white-20px'}`}
              >
                {element.text}
              </div>
              {!isLastElement && <img className="staking-line" src={line119} alt="Line 1" />}
            </React.Fragment>
          );

        return (
          <React.Fragment key={_}>
            <div
              onClick={() => element.onSelection()}
              className={`staking-1 ${element.selected ? 'oxanium-bold-white-19px' : 'oxanium-normal-white-15px'}`}
            >
              {element.text}
            </div>
            {!isLastElement && <img className="staking-line" src={line119} alt="Line 1" />}
          </React.Fragment>
        );
      })}
    </div>
  );
};
