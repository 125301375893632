import 'ethers';
import { BigNumber, Contract, Signer } from 'ethers';
import { useWeb3React } from '@web3-react/core';

import erc20 from '../abis/erc20.json';
import dexiUsdcStake from '../abis/dexiUsdcStake.json';
import { Web3Provider } from '@ethersproject/providers';
import ERC20 from './ERC20';
import Web3 from 'web3';
import { getDisplayBalance } from 'helpers/tokenUtils';

interface Contracts {
  [key: string]: Contract;
}

interface UserInfoUsdcStake {
  user: string;
  stakedAmount: BigNumber;
  rewardAmount: BigNumber;
  lastOperation: BigNumber;
  unlockTime: BigNumber;
}

const DexiAddress = '0x65ba64899c2c7dbfdb5130e42e2cc56de281c78b';

const DGVAddress = '0xDF6E5140Cc4d9Cf4F718F314C02fDd90622B31f6';
const DVTAddress = '0xe13206bbA95ab124b439eaD9C4Fe53e495D85E49';
const USDCAddress = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174';

const getUsdc = (provider: Web3Provider | Signer) => {
  return new ERC20(USDCAddress, provider, 'USDC', 6);
};
const getDexi = (provider: Web3Provider | Signer) => {
  return new ERC20(DexiAddress, provider, 'DEXI', 9);
};

const getDGV = (provider: Web3Provider | Signer) => {
  return new ERC20(DGVAddress, provider, 'DGV', 9);
};

const getDVT = (provider: Web3Provider | Signer) => {
  return new ERC20(DVTAddress, provider, 'DVT', 9);
};

const contracts: Contracts = {
  DEXI: new Contract(DexiAddress, erc20.abi),
  USDC: new Contract(USDCAddress, erc20.abi),
  DGV: new Contract(DGVAddress, erc20.abi),
  DVT: new Contract(DVTAddress, erc20.abi),
  DEXIUSDCSTAKE30: new Contract(dexiUsdcStake.address30, dexiUsdcStake.abi),
  DEXIUSDCSTAKE60: new Contract(dexiUsdcStake.address60, dexiUsdcStake.abi),
  DEXIUSDCSTAKE90: new Contract(dexiUsdcStake.address90, dexiUsdcStake.abi),
  DEXIUSDCSTAKE180: new Contract(dexiUsdcStake.address180, dexiUsdcStake.abi),
  DEXIDEXISTAKE30: new Contract(dexiUsdcStake.addressDEXI30, dexiUsdcStake.abi),
  DEXIDEXISTAKE60: new Contract(dexiUsdcStake.addressDEXI60, dexiUsdcStake.abi),
  DEXIDEXISTAKE90: new Contract(dexiUsdcStake.addressDEXI90, dexiUsdcStake.abi),
  DEXIDEXISTAKE180: new Contract(dexiUsdcStake.addressDEXI180, dexiUsdcStake.abi),
  DGVDGVSTAKE30: new Contract(dexiUsdcStake.addressDGV30, dexiUsdcStake.abi),
  DGVDGVSTAKE60: new Contract(dexiUsdcStake.addressDGV60, dexiUsdcStake.abi),
  DGVDGVSTAKE90: new Contract(dexiUsdcStake.addressDGV90, dexiUsdcStake.abi),
  DGVDGVSTAKE180: new Contract(dexiUsdcStake.addressDGV180, dexiUsdcStake.abi),
};

const getUsdcContract = (provider: Web3Provider | Signer) => {
  return contracts.USDC.connect(provider);
};
const getDexiContract = (provider: Web3Provider | Signer) => {
  return contracts.DEXI.connect(provider);
};
const getDGVContract = (provider: Web3Provider | Signer) => {
  return contracts.DGV.connect(provider);
};
const getDVTContract = (provider: Web3Provider | Signer) => {
  return contracts.DVT.connect(provider);
};

function getContract(contractName: string) {
  return contracts[contractName];
}

async function getTotalRewards(contractName: string, userAddress: string, provider: Web3Provider): Promise<BigNumber> {
  const contract = contracts[contractName].connect(provider.getSigner());

  return await contract.getTotalRewards(userAddress);
}

async function getApr(contractName: string, provider: Web3Provider): Promise<String> {
  const contract = contracts[contractName].connect(provider.getSigner());

  const secondapr = await contract._usdcPerSecondPerUsdc();
  return Number(
    getDisplayBalance({
      balance: secondapr.mul(3600 * 24 * 365).div(1e8),
      fractionDigits: 2,
      decimals: contractName.includes('DEXIDEX') || contractName.includes('DGV') ? 7 : 4,
    }),
  ).toFixed(1);
}

async function getUserStakedInfo(
  contractName: string,
  userAddress: string,
  provider: Web3Provider,
): Promise<UserInfoUsdcStake> {
  const contract = contracts[contractName].connect(provider.getSigner());

  return await contract.userInfo(userAddress);
}

async function getUserTotalRewardsInDexi(
  contractName: string,
  userAddress: string,
  provider: Web3Provider,
): Promise<Number> {
  const contract = contracts[contractName].connect(provider.getSigner());

  return Number(
    getDisplayBalance({ balance: await contract.getCurrentDexiRewards(userAddress), decimals: 9, fractionDigits: 9 }),
  );
}

async function getTotalUsers(contractName: string, provider: Web3Provider): Promise<Number> {
  const contract = contracts[contractName].connect(provider.getSigner());
  return Number(await contract.getTotalUsers());
}

async function getRewardsGlobal(contractName: string, provider: Web3Provider): Promise<Number> {
  const contract = contracts[contractName].connect(provider.getSigner());

  const result = Number(
    getDisplayBalance({
      balance: (await contract.totalUsdcAwarded()).add(
        await contract.getTotalUsdcRewardsAt((Date.now() / 1000).toFixed(0)),
      ),
      decimals: contractName.includes('DEXIDEX') || contractName.includes('DGV') ? 9 : 6,
      fractionDigits: contractName.includes('DEXIDEX') || contractName.includes('DGV') ? 0 : 2,
    }),
  );
  return result;
}

async function getLockPeriod(contractName: string, provider: Web3Provider): Promise<Number> {
  const contract = contracts[contractName].connect(provider.getSigner());

  return Number(await contract._lockPeriod());
}

async function getStakedFunds(contractName: string, provider: Web3Provider): Promise<Number> {
  const contract = contracts[contractName].connect(provider.getSigner());

  return Number(
    getDisplayBalance({
      balance: await contract.getStakedFundsAt(Math.round(Date.now() / 1000)),
      decimals: contractName.includes('DEXIDEX') || contractName.includes('DGV') ? 9 : 6,
      fractionDigits: 0,
    }),
  );
}

async function deposit(amount: number, contractName: string, provider: Web3Provider): Promise<void> {
  const contract = contracts[contractName].connect(provider.getSigner());

  await contract.deposit(
    BigNumber.from(contractName.includes('DEXIDEX') || contractName.includes('DGV') ? 1e9 * amount : 1e6 * amount),
  );
}

async function withdraw(contractName: string, provider: Web3Provider): Promise<void> {
  const contract = contracts[contractName].connect(provider.getSigner());

  await contract.withdraw();
}

async function claim(contractName: string, provider: Web3Provider): Promise<void> {
  const contract = contracts[contractName].connect(provider.getSigner());

  await contract.claim();
}

const getDexiBalance = async (address: string, provider: Web3Provider) => {
  const contract = contracts['DEXI'].connect(provider.getSigner());
  const result = await contract.balanceOf(address);
  return result;
};

const getDgvBalance = async (address: string, provider: Web3Provider) => {
  const contract = contracts['DGV'].connect(provider.getSigner());
  const result = await contract.balanceOf(address);
  return result;
};

const getUsdcBalance = async (address: string, provider: Web3Provider) => {
  const contract = contracts['USDC'].connect(provider.getSigner());
  const result = await contract.balanceOf(address);
  return result;
};

const getTokenBalance = async (tokenAddress: string, address: string, provider: Web3Provider) => {
  const contract = new Contract(tokenAddress, erc20.abi).connect(provider.getSigner());
  const result = await contract.balanceOf(address);
  return result;
};

export type { UserInfoUsdcStake };
export {
  DexiAddress,
  USDCAddress,
  getContract,
  getUsdc,
  getDexi,
  getDGV,
  getDVT,
  getUsdcContract,
  getDexiContract,
  getDGVContract,
  getDVTContract,
  getUserStakedInfo,
  getTotalRewards,
  getTotalUsers,
  deposit,
  withdraw,
  getDexiBalance,
  getDgvBalance,
  getUsdcBalance,
  getTokenBalance,
  getRewardsGlobal,
  getApr,
  getStakedFunds,
  getLockPeriod,
  getUserTotalRewardsInDexi,
  claim,
};
