import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import WalletReducer from './reducers/wallet';
import BalanceReducer from './reducers/balance';
import ContractReducer from './slices/contracts';
import { composeWithDevTools } from 'redux-devtools-extension';

/* export const store = createStore<any, any, any, any>(
  combineReducers({
    wallet: WalletReducer,
    balances: BalanceReducer,
    contracts: ContractReducer,
  }),
  applyMiddleware(thunk, logger),
);
 */
/* export default function configureStore1(preloadedState: any) {
  const middlewares = [logger, thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(
    combineReducers({
      wallet: WalletReducer,
      balances: BalanceReducer,
      contracts: ContractReducer,
    }),
    preloadedState,
    composedEnhancers,
  );

  return store;
}
 */

import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
  reducer: {
    contract: ContractReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch