import { useCallback, useEffect, useMemo, useState } from 'react';

import { BigNumber } from 'ethers';
import { getUsdcBalance } from 'services/dexiStakeService';
import { pooling } from 'helpers/commons';
import { getDisplayBalance } from 'helpers/tokenUtils';
import { useWeb3React } from '@web3-react/core';
import { setContractData } from 'store/slices/contracts';
import { useDispatch } from 'react-redux';
import { useAppStore } from './useAppStore';

const DECIMALS = 6;
const FRACTION_DIGITS = 6;

const useUsdc = () => {
  const [balance, setBalance] = useState<BigNumber | null>(null);
  const dispatch = useDispatch();
  const { stakeType, activeContract } = useAppStore();
  const usdcBalance = useMemo(() => {
    if (balance === null) return null;
    return getDisplayBalance({ balance, decimals: DECIMALS, fractionDigits: FRACTION_DIGITS });
  }, [balance]);

  const { provider, account, isActive } = useWeb3React();
  const isLoggedin = !!account && isActive;

  const fetchUsdcBalance = useCallback(async () => {
    const library = provider;
    if (isLoggedin && library) {
      console.log(account, library);
      getUsdcBalance(account, library)
        .then((r: any) => {
          const dexiBalance = getDisplayBalance({ balance: r, decimals: DECIMALS, fractionDigits: FRACTION_DIGITS });
          if (stakeType === 'USDC') {
            dispatch(setContractData({ ownBalance: dexiBalance, contractName: activeContract }));
          }
        })
        .catch((e) => console.error(e));
    }
  }, [account, stakeType, activeContract]);

  useEffect(() => {
    if (stakeType === 'USDC') {
      if (account && !usdcBalance) {
        fetchUsdcBalance().catch((err) => console.error(`Failed to fetch balance: ${err.stack}`));
      }
    }
  }, [account, stakeType, activeContract]);

  return {
    usdcBalance,
  };
};

export { useUsdc };
