import { FC } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import 'components/Group3/Group3.css';

interface IButton {
  children?: React.ReactNode;
}

export const ConnectButton: FC<IButton> = (props) => {

    return (
      <div className="overlap-group7">
        <div className="staking-button group-44">
          <div className={`group-3-4`}>
            <div className="overlap-group2-1">
              <div className="overlap-group-8">
                <div className="overlap-group-8">
                  <div className="rectangle-374-2"></div>
                  <div className="rectangle-375-2"></div>
                </div>
                <div className="ellipse-1-5"></div>
                <div className="ellipse-2-6"></div>
              </div>
              <div className="connect-wallet oxanium-light-white-24px">{props.children}</div>
            </div>
          </div>
        </div>
        <div className="rectangle-373"></div>
      </div>
    );

};
