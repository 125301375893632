import { useCallback, useEffect, useMemo, useState } from 'react';

import { BigNumber } from 'ethers';
import { getDexiBalance } from 'services/dexiStakeService';
import { pooling } from 'helpers/commons';
import { getDisplayBalance } from 'helpers/tokenUtils';
import { useWeb3React } from '@web3-react/core';
import { setContractData } from 'store/slices/contracts';
import { useDispatch } from 'react-redux';
import { useInterval } from 'usehooks-ts';
import { useAppStore } from './useAppStore';

const DECIMALS = 9;
const FRACTION_DIGITS = 2;

const useDexi = () => {
  const [balance, setBalance] = useState<BigNumber | null>(null);
  const dispatch = useDispatch();
  const { stakeType, activeContract } = useAppStore();
  const dexiBalance = useMemo(() => {
    if (balance === null) return null;
    return getDisplayBalance({ balance, decimals: DECIMALS, fractionDigits: FRACTION_DIGITS });
  }, [balance]);

  const { connector, provider: library, chainId, account, isActive: active } = useWeb3React();
  const isLoggedin = !!account && active;

  const fetchDexiBalance = useCallback(async () => {
    if (isLoggedin && library) {
      console.log(account, library);
      /**
       *
       */
      getDexiBalance(account, library)
        .then((r: any) => {
          const dexiBalance = getDisplayBalance({ balance: r, decimals: DECIMALS, fractionDigits: FRACTION_DIGITS });
          if (stakeType === 'DEXI') {
            dispatch(setContractData({ ownBalance: dexiBalance, contractName: activeContract }));
          }
        })
        .catch((e) => console.error(e));
    }
  }, [account, stakeType, activeContract]);

  useEffect(() => {
    if (stakeType === 'DEXI') {
      if (account && !dexiBalance) {
        fetchDexiBalance().catch((err) => console.error(`Failed to fetch balance: ${err.stack}`));
      }
    }
  }, [account, stakeType, activeContract]);

  return {
    dexiBalance,
  };
};

export { useDexi };
