import { useAppStore } from 'hooks/useAppStore';
import { FC, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setContract } from 'store/slices/contracts';
import { useMediaQuery } from 'usehooks-ts';
import { path3119, path3139, path6119, path6139, path7119, path7139 } from '../ui';

interface IStakingSelect {
}
const ROW_HEIGHT = 48;
const ROW_HEIGHT_INNER = 46;
const TOP_HEIGHT_BOTTOM = -50;

const ROW_HEIGHT_MOBILE = 48;
const ROW_HEIGHT_INNER_MOBILE = 46;
const TOP_HEIGHT_BOTTOM_MOBILE = -50;

export const StakingSelect: FC<IStakingSelect> = (props) => {
  const { contractName, stakeType } = useAppStore();
  const { list } = useSelector((state: RootState) => state.contract);
  const dispatch = useDispatch();
  const mappedList = useMemo(() => {
    const result = Object.entries(list)
      .filter(([key, value]) => {
        return value.stakeType === stakeType;
      })
      .map(([key, value]) => {
        const { lockPeriodFixed, contractName } = value;
        return {
          name: contractName ?? '',
          text: `${((lockPeriodFixed ?? 0) / 60 / 60 / 24).toFixed(0)} Days Lock`,
          onClick: () => {
            dispatch(setContract(key));
          },
        };
      });
    return result && result.length > 0 ? result : [{ name: '', text: '', onClick: () => {} }];
  }, [stakeType]);
  const isDesktop = useMediaQuery('(min-width:800px)');
  const [toggle, setToggle] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    mappedList.forEach((item, index) => {
      if (item.name == contractName) {
        setSelectedIndex(index);
      }
    });
  }, [toggle, stakeType]);
  const extraHeight = mappedList.length || 1;
  //height per elem
  if (isDesktop) {
    return (
      <div className="overlap-group1">
        <div
          className="rectangle-18-2"
          style={
            toggle
              ? {
                  height: `${extraHeight * ROW_HEIGHT}px`,
                }
              : {}
          }
        ></div>
        <div
          className="rectangle-19"
          style={
            toggle
              ? {
                  height: `${extraHeight * ROW_HEIGHT_INNER}px`,
                }
              : {}
          }
        ></div>
        <div className="address oxanium-normal-white-18px staking--select-list">
          {!toggle ? (
            <div className="staking--select-button" onClick={() => setToggle(!toggle)}>
              {mappedList?.[selectedIndex].text ?? '--'}
            </div>
          ) : (
            mappedList.map((item, i) => (
              <div
                className="staking--select-button"
                key={i}
                onClick={() => {
                  item.onClick?.();
                  setToggle(!toggle);
                }}
              >
                {item.text ?? '--'}
              </div>
            ))
          )}
        </div>
        <img className="path-3" src={path3119} alt="Path 3" />
        <div className="group-1">
          <div className="group-1">
            <div className="rectangle-374"></div>
            <div
              className="rectangle-375"
              style={toggle ? { top: `${TOP_HEIGHT_BOTTOM + Math.abs((extraHeight - 1) * TOP_HEIGHT_BOTTOM)}px` } : {}}
            ></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="overlap-group3-8">
      <div
        className="rectangle-18-34"
        style={
          toggle
            ? {
                height: `${extraHeight * ROW_HEIGHT_MOBILE}px`,
              }
            : {}
        }
      ></div>
      <div
        className="rectangle-19-25"
        style={
          toggle
            ? {
                height: `${extraHeight * ROW_HEIGHT_INNER_MOBILE}px`,
              }
            : {}
        }
      ></div>
      <div className="address-20 oxanium-normal-white-14px staking--select-list">
        {!toggle ? (
          <div className="staking--select-button" onClick={() => setToggle(!toggle)}>
            {mappedList?.[selectedIndex].text ?? '--'}
          </div>
        ) : (
          mappedList.map((item, i) => (
            <div
              className="staking--select-button"
              key={i}
              onClick={() => {
                item.onClick?.();
                setToggle(!toggle);
              }}
            >
              {item.text ?? '--'}
            </div>
          ))
        )}
      </div>
      <img className="path-3-12" src={path3139} alt="Path 3" />
      <div className="group-31-4">
        <img className="path-6-20" src={path6139} alt="Path 6" />
        <div className="rectangle-374-12"></div>
        <img className="path-7-20" src={path7139} alt="Path 7" />
      </div>
    </div>
  );
};
