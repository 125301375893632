import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'styles/home1920px.css';
import 'styles/home800px.css';
import 'styles/styleguide.css';
import 'styles/home390px.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import { allconnectorsAndHooks } from 'helpers/connectors';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { MetaMask } from '@web3-react/metamask';
import { Network } from '@web3-react/network';

function getLibrary(provider: any) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider connectors={allconnectorsAndHooks}>
      <Provider store={store}>
        <App />
      </Provider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
