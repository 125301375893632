import { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { injectedConnector, networkConnector, walletConnector } from 'helpers/connectors';
import { MetaMask } from '@web3-react/metamask';
import { Network } from '@web3-react/network';
import { WalletConnect } from '@web3-react/walletconnect-v2';

let connector: Network | MetaMask | WalletConnect | null = null;
connector = networkConnector;

const useAuth = () => {
  const { provider, account, chainId } = useWeb3React();
  const [keepConnection, setKeepConnection] = useState(localStorage.getItem('DexiStakeConnect') == 'KEEP');
  useEffect(() => {
    const fn = async () => {
      if (localStorage.getItem('DexiStakeConnect') == 'KEEP') {
        console.log(localStorage.getItem('DexiStakeConnect'));
        if (!account) {
          await injectedConnector.connectEagerly();
        }
        if (!account) {
          await walletConnector.connectEagerly();
        }
      }
    };
    fn();
  }, [account, keepConnection]);

  const login = useCallback(
    async (walletId = 0) => {
      if (walletId === 1) {
        connector = injectedConnector;
      } else if (walletId === 2) {
        connector = walletConnector;
      }
      localStorage.setItem('DexiStakeConnect', 'KEEP');
      setKeepConnection(localStorage.getItem('DexiStakeConnect') == 'KEEP');

      console.log(localStorage.getItem('DexiStakeConnect'));
      await connector?.activate();
    },
    [provider, chainId],
  );

  const logout = useCallback(() => {
    localStorage.setItem('DexiStakeConnect', 'DISCONNECT');
    setKeepConnection(localStorage.getItem('DexiStakeConnect') == 'KEEP');
    console.log('DISCONNECT', localStorage.getItem('DexiStakeConnect'));
    if (connector?.deactivate) {
      void connector.deactivate();
    } else {
      void connector?.resetState();
    }
  }, [provider]);

  return { login, logout };
};

export default useAuth;
