import React from "react";
import "./Group51.css";
import logo from "../../assets/img/arrow-1@1x.png";
import { useMediaQuery } from "usehooks-ts";

const Group51 = (props: { className?: string; text?: string }) => {
  const { text } = props;
  const isMobile = useMediaQuery("(max-width:1023px)");

  return (
    <div className={`group-51 ${isMobile ? "group-51-4" : ""}`}>
      <img className="arrow" src={logo} alt="arrow" />
      {text && <div className="apy-rate oxanium-normal-white-21px">{text}</div>}
    </div>
  );
};

export default Group51;
