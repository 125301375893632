import { useState } from 'react'
import { Image, Modal } from 'react-bootstrap'
import { WalletElements } from './index.styled'

export default function Connect(data: any) {
  const { onClose, onWalletSelect } = data
  const [show, setShow] = useState(true)

  const handleClose = () => {
    setShow(false)
    onClose()
  }
  const Wallets = [
    { icon: '/images/metamask.png', name: 'Metamask' },
    { icon: '/images/wallet-connect.png', name: 'WalletConnect' },
  ]

  const selectWallet = (walletName: string) => {
    setShow(false)
    onWalletSelect(walletName)
    onClose()
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <Modal.Title className="mb-4">Connect Wallet</Modal.Title>
        <WalletElements className="wallets">
          {Wallets.map((wallet) => (
            <span key={wallet.name} onClick={() => selectWallet(wallet.name)}>
              <Image src={wallet.icon} />
              {wallet.name}
            </span>
          ))}
        </WalletElements>
      </Modal.Body>
    </Modal>
  )
}
