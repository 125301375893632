import { CoinGeckoChart } from 'components/CoinGeckoChart';
import Group50 from 'components/Group50';
import Group51 from 'components/Group51';
import Group52 from 'components/Group52';
import Group53 from 'components/Group53';
import Header from 'components/Header';
import { InfoCard } from 'components/InfoCard';
import { SingleCard } from 'components/SingleCard';
import { StakingCard } from 'components/StakingCard';
import { dexi19, dexioprotocolVerbiage219, ConnectButton } from 'components/ui';
import { useAppStore } from 'hooks/useAppStore';
import { useDexi } from 'hooks/useDexi';
import { useDGV } from 'hooks/useDGV';
import { useUsdc } from 'hooks/useUsdc';

const Home = () => {
  //const { dexiBalance } = useDexi();
  const { usdcBalance } = useUsdc();
  const { dexiBalance } = useDexi();

  const { dgvBalance } = useDGV();
  const { apyRate, awarded, stakers, stakeType, total } = useAppStore();
  return (
    <div className="home">
      <div className="new-home">
        <div className="new-header">
          <div>
            <div className="overlap-group8">
              <div className="staking orbitron-medium-white-19px">{'STAKING'}</div>
              <img className="dexi" src={dexi19} alt="DEXI" />
              <img className="dexioprotocol-verbiage-2" src={dexioprotocolVerbiage219} alt="Dexioprotocol Verbiage 2" />
            </div>
          </div>
          <div className="new-header--right">
            <ConnectButton>
              <Header />
            </ConnectButton>
          </div>
        </div>
        <div className="group-63">
          <SingleCard text={`${total ?? '--'} ${stakeType}`}>
            <Group53 text="Total Currently Staked" />
          </SingleCard>
          <SingleCard text={stakeType === 'USDC' ? `$${awarded ?? '--'} in DEXI` : `${awarded ?? '--'} ${stakeType}`}>
            <Group52 text="Lifetime Awards" />
          </SingleCard>
          <SingleCard text={`${apyRate ?? '--'} %`}>
            <Group51 text="APR" />
          </SingleCard>
          <SingleCard text={`${stakers ?? '--'}`}>
            <Group50 text="Lifetime Stakers" />
          </SingleCard>
        </div>

        <div className="overlap-group-container-1">
          <StakingCard />
          {/*<InfoCard text={"aisjdbfaisjdbf"}/>
          <CoinGeckoChart />*/}
        </div>
      </div>
    </div>
  );
};

export { Home };
