import { useAppStore } from 'hooks/useAppStore';
import { FC, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { setStakeType } from 'store/slices/contracts';
import { useMediaQuery } from 'usehooks-ts';
import { group3239, path6219, path6239, path7219, path7239 } from '../ui';

interface IButton {
  children?: ReactNode;
}

export const StakingButton: FC<IButton> = (props) => {
  const dispatch = useDispatch();

  const { stakeType } = useAppStore();
  const isDesktop = useMediaQuery('(min-width:800px)');
  const onClick = (stakeTypeSelection: string) => {
    dispatch(setStakeType(stakeTypeSelection));
  };
  if (isDesktop) {
    return (
      <div className="flex flex-row">
        <div key={'USDC'} className="flex group-21">
          <div
            className={`staking-button group-3-2 ${stakeType === 'USDC' ? 'staking-button-active' : ''}`}
            onClick={() => onClick('USDC')}
          >
            <div className="overlap-group1-1">
              <div className="rectangle-13-4"></div>
              <div className="staking-usdc oxanium-light-white-19px">USDC</div>
              <div className="group-2">
                <div className="group-2">
                  <div className="rectangle-374-1"></div>
                  <div className="rectangle-375-1"></div>
                </div>
                <img className="path-6-1" src={path6219} />
                <img className="path-7-1" src={path7219} />
              </div>
            </div>
          </div>
        </div>
        <div key={'DEXI'} className="flex group-21">
          <div
            className={`staking-button  group-3-2 ${stakeType === 'DEXI' ? 'staking-button-active' : ''}`}
            onClick={() => onClick('DEXI')}
          >
            <div className="overlap-group1-1">
              <div className="rectangle-13-4"></div>
              <div className="staking-dexi oxanium-light-white-19px">DEXI</div>
              <div className="group-2">
                <div className="group-2">
                  <div className="rectangle-374-1"></div>
                  <div className="rectangle-375-1"></div>
                </div>
                <img className="path-6-1" src={path6219} />
                <img className="path-7-1" src={path7219} />
              </div>
            </div>
          </div>
        </div>
        <div key={'DGV'} className="flex group-21">
          <div
            className={`staking-button  group-3-2 ${stakeType === 'DGV' ? 'staking-button-active' : ''}`}
            onClick={() => onClick('DGV')}
          >
            <div className="overlap-group1-1">
              <div className="rectangle-13-4"></div>
              <div className="staking-dexi oxanium-light-white-19px">DGV</div>
              <div className="group-2">
                <div className="group-2">
                  <div className="rectangle-374-1"></div>
                  <div className="rectangle-375-1"></div>
                </div>
                <img className="path-6-1" src={path6219} />
                <img className="path-7-1" src={path7219} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row space-around">
      <div
        key={'USDC'}
        className={`staking-button group-21-6 ${stakeType === 'USDC' ? 'staking-button-active' : ''} `}
        onClick={() => onClick('USDC')}
      >
        <div className="group-container-38">
          <div className="group-3-35">
            <div className="staking-usdc-6">USDC</div>
          </div>
          <div className="group-32-4" style={{ backgroundImage: `url(${group3239})` }}>
            <img className="path-6-21" src={path6239} alt="Path 6" />
            <img className="path-7-21" src={path7239} alt="Path 7" />
          </div>
        </div>
      </div>
      <div
        key={'DEXI'}
        className={`staking-button group-21-6 ${stakeType === 'DEXI' ? 'staking-button-active' : ''} `}
        onClick={() => onClick('DEXI')}
      >
        <div className="group-container-38">
          <div className="group-3-35">
            <div className="staking-dexi-6">DEXI</div>
          </div>
          <div className="group-32-4" style={{ backgroundImage: `url(${group3239})` }}>
            <img className="path-6-21" src={path6239} alt="Path 6" />
            <img className="path-7-21" src={path7239} alt="Path 7" />
          </div>
        </div>
      </div>
      <div
        key={'DGV'}
        className={`staking-button group-21-6 ${stakeType === 'DGV' ? 'staking-button-active' : ''} `}
        onClick={() => onClick('DGV')}
      >
        <div className="group-container-38">
          <div className="group-3-35">
            <div className="staking-dexi-6">DGV</div>
          </div>
          <div className="group-32-4" style={{ backgroundImage: `url(${group3239})` }}>
            <img className="path-6-21" src={path6239} alt="Path 6" />
            <img className="path-7-21" src={path7239} alt="Path 7" />
          </div>
        </div>
      </div>
    </div>
  );
};
