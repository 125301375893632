import { FC } from 'react';
import { useMediaQuery } from 'usehooks-ts';

interface IInput {
  onChange: (input: string) => void;
  disabled?: boolean;
  value: any;
}
export const StakingInput: FC<IInput> = (props) => {
  const isDesktop = useMediaQuery('(min-width:800px)');

  if (isDesktop)
    return (
      <div className="overlap-group2">
        <div className="rectangle-18-3"></div>
        <div className="rectangle-19-1"></div>
        <input
          type={'number'}
          min="0"
          step={0.000001}
          value={props.value ?? undefined}
          autoFocus
          className="staking-input oxanium-bold-white-27px"
          onChange={(e) => {
            props.onChange(e?.target?.value);
          }}
          disabled={props.disabled ?? false}
        ></input>
      </div>
    );

  return (
    <div className="overlap-group2-10">
      <div className="rectangle-18-33"></div>
      <div className="rectangle-19-24"></div>
      <div className="address-19 oxanium-bold-white-21px">{}</div>
      <input
        type={'number'}
        min="0"
        step={0.000001}
        value={props.value ?? undefined}
        autoFocus
        className="staking-input oxanium-bold-white-21px"
        onChange={(e) => {
          props.onChange(e?.target?.value);
        }}
        disabled={props.disabled ?? false}
      ></input>
    </div>
  );
};
