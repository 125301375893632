import React from "react";
import "./Group52.css";
import logo from "../../assets/img/coinss-1@1x.png";
import { useMediaQuery } from "usehooks-ts";

const Group52 = (props: { className?: string; text?: string }) => {
  const { text } = props;
  const isMobile = useMediaQuery("(max-width:1023px)");

  return (
    <div className={`group-52 ${isMobile ? "group-52-4" : ""}`}>
      <img className="coinss" src={logo} alt="coinss" />
      {text && (
        <div className="awarded-dexi oxanium-normal-white-21px">{text}</div>
      )}
    </div>
  );
};

export default Group52;
