import { useWeb3React } from '@web3-react/core';
import { BigNumber, Contract } from 'ethers';
import { useCallback, useEffect, useState } from 'react';

import ERC20 from '../services/ERC20';

const useAllowance = (token: Contract | null, spender: string, pendingApproval?: boolean) => {
  const [allowance, setAllowance] = useState<BigNumber | null>(null);
  const { account } = useWeb3React();
  const [refresh, setRefresh] = useState(false);

  const fetchAllowance = useCallback(async () => {
    if (!account || !token) {
      return setAllowance(BigNumber.from(0));
    }
    const allowanceInternal = await token.allowance(account, spender);
    setAllowance(allowanceInternal);
  }, [account, spender, token]);

  useEffect(() => {
    setInterval(() => {
      setRefresh(false);
    }, 30000);
  }, []);

  useEffect(() => {
    if (account && spender && token) {

      fetchAllowance().catch((err) => console.error(`Failed to fetch allowance: ${err.stack}`));
      setRefresh(true);
    }
  }, [account, refresh]);

  return allowance;
};

export default useAllowance;
