import { useCallback, useEffect, useMemo, useState } from 'react';

import { BigNumber } from 'ethers';
import {
  getApr,
  getLockPeriod,
  getRewardsGlobal,
  getStakedFunds,
  getTotalRewards,
  getTotalUsers,
  getUserStakedInfo,
  getUserTotalRewardsInDexi,
} from 'services/dexiStakeService';
import { pooling } from 'helpers/commons';
import { getDisplayBalance } from 'helpers/tokenUtils';
import { useWeb3React } from '@web3-react/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  setStakers,
  setApr,
  setGlobalRewards,
  setStakeFunds,
  setLockPeriod,
  setDexiUserRewards,
  setUsdcUserRewards,
  setUserStakeInfo,
  setContractData,
} from 'store/slices/contracts';
import { useInterval } from 'usehooks-ts';
import { useAppStore } from './useAppStore';

const useDexiStakeContract = () => {
  const { provider: library, account, isActive: active } = useWeb3React();
  const dispatch = useDispatch();
  const activeContract = useSelector((state: RootState) => state.contract.activeContract);
  const { stakeType } = useAppStore();
  const [refresh, setRefresh] = useState(false);
  const [intervalID, setIntervalID] = useState<NodeJS.Timeout>();

  const [currentContract, setCurrentContract] = useState(activeContract);

  const isLoggedin = !!account && active;

  const fetchContractInfo = async () => {
    if (isLoggedin && library) {
      console.log('Waiting calls', activeContract);
      getTotalUsers(activeContract, library)
        .then((r: any) => {
          dispatch(setStakers({ stakers: r, contractName: activeContract }));
        })
        .catch((e) => console.error(e));
      getApr(activeContract, library)
        .then((r: any) => dispatch(setApr({ apyRate: r, contractName: activeContract })))
        .catch((e) => console.error(e));
      getRewardsGlobal(activeContract, library)
        .then((r: any) => dispatch(setGlobalRewards({ awarded: r, contractName: activeContract })))
        .catch((e) => console.error(e));
      getStakedFunds(activeContract, library)
        .then((r: any) => dispatch(setStakeFunds({ total: r, contractName: activeContract })))
        .catch((e) => console.error(e));
      getLockPeriod(activeContract, library)
        .then((r: any) => dispatch(setLockPeriod({ lockPeriod: r, contractName: activeContract })))
        .catch((e) => console.error(e));
      getUserTotalRewardsInDexi(activeContract, account, library)
        .then((r: any) => dispatch(setDexiUserRewards({ dexiUserRewards: r, contractName: activeContract })))
        .catch((e) => console.error(e));
      getTotalRewards(activeContract, account, library)
        .then((r: any) =>
          dispatch(
            setUsdcUserRewards({
              usdcUserRewards: Number(
                getDisplayBalance({
                  balance: r,
                  decimals: stakeType === 'USDC' ? 6 : 9,
                  fractionDigits: stakeType === 'USDC' ? 6 : 9,
                }),
              ),
              contractName: activeContract,
            }),
          ),
        )
        .catch((e) => console.error(e));
      getUserStakedInfo(activeContract, account, library)
        .then((r: any) =>
          dispatch(
            setUserStakeInfo({
              userStakeInfo: {
                user: r.user,
                lastOperation: Number(r.lastOperation),
                rewardAmount: Number(
                  getDisplayBalance({
                    balance: r.rewardAmount,
                    decimals: stakeType === 'USDC' ? 6 : 9,
                    fractionDigits: 6,
                  }),
                ),
                stakedAmount: Number(
                  getDisplayBalance({
                    balance: r.stakedAmount,
                    decimals: stakeType === 'USDC' ? 6 : 9,
                    fractionDigits: 6,
                  }),
                ),
                unlockTime: Number(r.unlockTime),
              },
              contractName: activeContract,
            }),
          ),
        )
        .catch((e) => console.error(e));
    }
  };

  useEffect(() => {
    if (currentContract !== activeContract) {
      if (intervalID) {
        clearInterval(intervalID);
      }
      setCurrentContract(activeContract);
      setIntervalID(
        setInterval(() => {
          setRefresh(true);
        }, 10000),
      );
    }
  }, [activeContract]);

  useEffect(() => {
    console.log(refresh);
    fetchContractInfo().catch((err) => console.error(`Failed to fetch contractInfo: ${err.stack}`));
    setRefresh(false);
  }, [account, activeContract, refresh]);
};
export { useDexiStakeContract };
