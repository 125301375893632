import React from "react";
import "./Group53.css";
import logo from "../../assets/img/logo-1@1x.png";
import { useMediaQuery } from "usehooks-ts";

const Group53 = (props: { className?: string; text?: string }) => {
  const { text } = props;
  const isMobile = useMediaQuery("(max-width:1023px)");
  return (
    <div className={`group-53 ${isMobile ? "group-53-4" : ""}`}>
      <img className="logo" src={logo} alt="logo" />
      {text && (
        <div className="total-dexi-staked oxanium-normal-white-19px">
          {text}
        </div>
      )}
    </div>
  );
};

export default Group53;
