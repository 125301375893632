import './App.css';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import 'antd/dist/antd.min.css'; // or 'antd/dist/antd.less'
import { Home } from 'pages/Home';
import { useDexiStakeContract } from 'hooks/useDexiStakeContract';
import DocumentMeta from 'react-document-meta';

function App() {
  useDexiStakeContract();
  const meta = {
    title: 'DexioProtocol Staking',
    description: 'DexioProtocol Staking',
    canonical: 'https://staking.dexioprotocol.com/',
  };

  return (
    <div className="App">
      <DocumentMeta {...meta}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
        </BrowserRouter>
      </DocumentMeta>
    </div>
  );
}

export default App;
