import { useWeb3React } from '@web3-react/core';
import { Modal } from 'antd';
import useModal from 'antd/lib/modal/useModal';
import { useApprove, ApprovalState } from 'hooks/useApprove';
import { useAppStore } from 'hooks/useAppStore';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  claim,
  deposit,
  getContract,
  getDexi,
  getDexiContract,
  getDGV,
  getDGVContract,
  getDVT,
  getDVTContract,
  getUsdc,
  getUsdcContract,
  withdraw,
} from 'services/dexiStakeService';
import { useMediaQuery } from 'usehooks-ts';
import { StakingButton, StakingInput, StakingMainButton, StakingSelect } from './ui';

import { group2639, path119, path139, path239, path819 } from './ui/PathImg';
import { StakingTop } from './ui/StakingTop';
import { BigNumber } from 'ethers';

interface IStakingCard {}

enum MODE {
  STAKE = 'STAKE',
  UNSTAKE = 'UNSTAKE',
  CLAIM = 'CLAIM',
}

export const StakingCard: FC<IStakingCard> = () => {
  const [amount, setAmount] = useState(0);

  const [mode, setMode] = useState<MODE>(MODE.STAKE);
  const isDesktop = useMediaQuery('(min-width:800px)');

  const [approveStatus, setAppoveStatus] = useState(ApprovalState.NOT_APPROVED);
  const [approveDVTStatus, setAppoveDVTStatus] = useState(ApprovalState.NOT_APPROVED);
  const { account, provider: library } = useWeb3React();
  const { contractName, apyRate, lockPeriodFixed, ownBalance, usdcUserRewards, userStakeInfo, stakeType } =
    useAppStore();

  const [usdcAmount, setUsdcAmount] = useState(0);
  const [dexiAmount, setDexiAmount] = useState(0);
  const [dgvAmount, setDgvAmount] = useState(0);
  const [dvtAmount, setDvtAmount] = useState(0);

  useEffect(() => {
    if (stakeType === 'USDC') {
      setUsdcAmount(amount * 10 ** 6);
    } else if (stakeType === 'DEXI') {
      setDexiAmount(amount * 10 ** 9);
    } else if (stakeType === 'DGV') {
      setDgvAmount(amount * 10 ** 9);
      setDvtAmount(Number(userStakeInfo?.stakedAmount ?? 0) * 10 ** 9);
    }
  }, [amount, stakeType, userStakeInfo]);

  const [approveStatusUsdc, approveUsdc] = useApprove(
    library ? getUsdcContract(library.getSigner(account)) : null,
    getContract(contractName)?.address,
    account,
    BigNumber.from('0x' + Math.floor(usdcAmount).toString(16)),
  );

  const [approveStatusDexi, approveDexi] = useApprove(
    library ? getDexiContract(library.getSigner(account)) : null,
    getContract(contractName)?.address,
    account,
    BigNumber.from('0x' + Math.floor(dexiAmount).toString(16)),
  );

  const [approveStatusDGV, approveDGV] = useApprove(
    library ? getDGVContract(library.getSigner(account)) : null,
    getContract(contractName)?.address,
    account,
    BigNumber.from('0x' + Math.floor(dgvAmount).toString(16)),
  );

  const [approveStatusDVT, approveDVT] = useApprove(
    library ? getDVTContract(library.getSigner(account)) : null,
    getContract(contractName)?.address,
    account,
    BigNumber.from('0x' + Math.floor(Number(dvtAmount)).toString(16)),
  );

  useEffect(() => {
    setAppoveStatus(
      stakeType === 'USDC' ? approveStatusUsdc : stakeType === 'DEXI' ? approveStatusDexi : approveStatusDGV,
    );
    setAppoveDVTStatus(approveStatusDVT);
  }, [stakeType, approveStatusDexi, approveStatusDGV, approveStatusUsdc, approveStatusDVT]);

  const unlockDateMemo = useMemo(() => {
    console.log('datememo');

    if (userStakeInfo?.unlockTime && Number(userStakeInfo?.unlockTime) > Number(0)) {
      const date = new Date(0);
      date.setUTCSeconds((userStakeInfo?.unlockTime ?? 0).valueOf());
      return date;
    } else {
      return null;
    }
  }, [userStakeInfo]);

  const [date, setDate] = useState(Date.now());
  useEffect(() => {
    setInterval(() => {
      setDate(Date.now());
    }, 10000);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    console.log('Clicked show modal button');
  };

  const handleOk = () => {
    if (library) {
      claim(contractName, library);
      setIsModalOpen(false);
    }
  };

  const handleOkDeposit = () => {
    if (library) {
      deposit(amount, contractName, library);

      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    console.log('Clicked cancel button');
  };

  if (isDesktop) {
    return (
      <div className="overlap-group6">
        <div className="overlap-group-4">
          <div className="rectangle-18-1"></div>
          <img className="path-1" src={path119} alt="Path 1" />
          <div className="rectangle-367"></div>
          <img className="path-8" src={path819} alt="Path 8" />
        </div>
        <div className="time">
          <div className="flex-col oxanium-bold-white-20px">
            <div className="time-period">Time Period</div>
            <div className="apr">APR</div>
            <div className="rewards">Rewards</div>
          </div>
          <div className="flex-col-1">
            <StakingSelect />
            <div className="percent oxanium-normal-white-20px">{`${apyRate ?? '--'} %`}</div>
            <div className="address-1 oxanium-normal-white-20px">
              {mode == MODE.STAKE
                ? `${Number(
                    (
                      (amount *
                        (apyRate && lockPeriodFixed ? (Number(apyRate) / (365 * 60 * 60 * 24)) * lockPeriodFixed : 0)) /
                      100
                    ).toFixed(2),
                  )} ${stakeType === 'USDC' ? 'USD in DEXI' : stakeType}`
                : stakeType === 'USDC'
                ? `$${usdcUserRewards?.toFixed(2)} in DEXI`
                : `${usdcUserRewards?.toFixed(2)} ${stakeType}`}
            </div>
          </div>
        </div>
        <div className="amount">
          <div className="flex-row">
            <div className="amount-to-stake oxanium-normal-white-18px">Staked Balance</div>
            <div className="available-balance-200000-dexi oxanium-normal-white-18px">
              <>
                {userStakeInfo?.stakedAmount ?? '--'} {stakeType}
              </>
            </div>
          </div>

          <div className="flex-row">
            <div className="amount-to-stake oxanium-normal-white-18px">Available Balance</div>
            <div className="available-balance-200000-dexi oxanium-normal-white-18px">
              <>
                {ownBalance ?? '--'} {stakeType}
              </>
            </div>
          </div>

          {/*
          <div className="overlap-group2">
            <div className="rectangle-18-3"></div>
            <div className="rectangle-19-1"></div>
            <div className="address-2 oxanium-bold-white-27px">{address3}</div>
          </div> */}
          <StakingInput disabled={mode != MODE.STAKE} value={amount} onChange={(v) => setAmount(Number(v))} />
        </div>

        <div className="dexiusd">
          {!((Number(userStakeInfo?.stakedAmount) ?? 0) > 0) ||
          !userStakeInfo?.unlockTime ||
          userStakeInfo?.unlockTime === 0 ||
          Number(userStakeInfo?.unlockTime) > date ? (
            <div className="timeInfo oxanium-bold-white-18px">No funds staked</div>
          ) : (
            <div className="timeInfo oxanium-bold-white-18px">
              Your Unlock Time {unlockDateMemo?.toLocaleDateString()} {unlockDateMemo?.toLocaleTimeString()}
            </div>
          )}
        </div>
        {mode === MODE.STAKE ? (
          approveStatus !== ApprovalState.APPROVED ? (
            <StakingMainButton
              onClick={() => {
                stakeType === 'USDC' ? approveUsdc() : stakeType === 'DEXI' ? approveDexi() : approveDGV();
              }}
            >
              Approve {stakeType}
            </StakingMainButton>
          ) : (
            <>
              <Modal title="Stake Confirm" open={isModalOpen} onOk={handleOkDeposit} onCancel={handleCancel}>
                <p>
                  {stakeType === 'DGV'
                    ? `By Staking DGV you will be issued DVT tokens. You will be able to use DVT to vote on governance proposals. You will be required to give back your DVT tokens to unstake your DGV.`
                    : stakeType === 'USDC'
                    ? 'In our continuing effort to stay current with the US regulatory environment, and given recent actions taken by the SEC against Kraken, Dexioprotocol had opted to end any NEW staking of USDC. However, in light of new information and our desire to offer the best products and services to our community, we have decided to reopen the USDC staking program.All USDC staking APRs have been adjusted up as well and that impacts any currently staked USDC retroactively. Please review the current staking APRs for each of the 4 terms periods and send us a message at support@dexioprotocol.com or join our Discord or Telegram if you have any questions.'
                    : 'Your assets will be locked for the duration of the staking lock. You will be able to claim your rewards and your assets only after the lock period ends.'}
                </p>
              </Modal>
              <StakingMainButton
                onClick={() => {
                  showModal();
                }}
              >
                Stake {stakeType}
              </StakingMainButton>
            </>
          )
        ) : mode === MODE.UNSTAKE ? (
          stakeType === 'DGV' && approveDVTStatus !== ApprovalState.APPROVED ? (
            <StakingMainButton
              onClick={() => {
                approveDVT();
              }}
            >
              Approve DVT
            </StakingMainButton>
          ) : (
            <StakingMainButton
              onClick={() => {
                if (library) {
                  withdraw(contractName, library);
                }
              }}
              disabled={
                unlockDateMemo == null || unlockDateMemo.getUTCSeconds() > date || unlockDateMemo.getUTCSeconds() == 0
              }
            >
              Unstake {stakeType}
            </StakingMainButton>
          )
        ) : (
          <>
            <Modal title="Claim" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
              <p>
                {`By "claiming" your rewards you will only be issued the rewards for this period and your original staked
                assets will be staked again. If you wish to receive all of your assets including the original
                staked amount, please use the "Unstake" function.`}
              </p>
            </Modal>
            <StakingMainButton
              onClick={() => {
                showModal();
              }}
              disabled={
                unlockDateMemo == null || unlockDateMemo.getTime() / 1000 > date || unlockDateMemo.getTime() == 0
              }
            >
              Claim
            </StakingMainButton>
          </>
        )}
        <div className="switchstake">
          <StakingButton />
        </div>
        <StakingTop
          tabs={[
            {
              text: 'Staking',
              onSelection: () => {
                setMode(MODE.STAKE);
                setAmount(0);
              },
              selected: mode === MODE.STAKE,
            },
            {
              text: 'Unstaking',
              onSelection: () => {
                setMode(MODE.UNSTAKE);
                if (userStakeInfo?.stakedAmount) {
                  setAmount(Number(userStakeInfo?.stakedAmount));
                }
              },
              selected: mode === MODE.UNSTAKE,
            },
            {
              text: 'Claiming',
              onSelection: () => {
                setMode(MODE.CLAIM);
                if (usdcUserRewards) {
                  setAmount(Number(usdcUserRewards.toFixed(2)));
                }
              },
              selected: mode === MODE.CLAIM,
            },
          ]}
        />

        <div className="sides">
          <div className="ellipse-1-3"></div>
          <div className="ellipse-2-4"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="group-container-37">
      <div className="group-13">
        <div className="group-13">
          <div className="group-3-33">
            <div className="overlap-group-55">
              <img className="path-1-12" src={path139} alt="Path 1" />
              <div className="rectangle-367-6"></div>
              <div className="time-period-6 oxanium-bold-white-15px">Time period</div>
              <div className="apr-6 oxanium-bold-white-15px">APR</div>
              <div className="rewards-6 oxanium-bold-white-15px">Rewards</div>
              <div className="percent-6 oxanium-normal-white-15px">{`${apyRate ?? '--'} %`}</div>
              <div className="address-18 oxanium-normal-white-15px">
                {mode == MODE.STAKE
                  ? `${Number(
                      (
                        (amount *
                          (apyRate && lockPeriodFixed
                            ? (Number(apyRate) / (365 * 60 * 60 * 24)) * lockPeriodFixed
                            : 0)) /
                        100
                      ).toFixed(2),
                    )} ${stakeType === 'USDC' ? 'USD in DEXI' : stakeType}`
                  : stakeType === 'USDC'
                  ? `$${usdcUserRewards?.toFixed(2)} in DEXI`
                  : `${usdcUserRewards?.toFixed(2)} ${stakeType}`}
              </div>
              <img className="path-2-1" src={path239} alt="Path 2" />
            </div>
          </div>
          <div className="group-26-3" style={{ backgroundImage: `url(${group2639})` }}>
            <div className="ellipse-1-40"></div>
            <div className="ellipse-2-47"></div>
          </div>
          <div className="switchstake-mobile">
            <StakingButton />
          </div>
          <StakingTop
            tabs={[
              {
                text: 'Staking',
                onSelection: () => {
                  console.log('selection');
                  setMode(MODE.STAKE);
                  setAmount(0);
                },
                selected: mode === MODE.STAKE,
              },
              {
                text: 'Unstaking',
                onSelection: () => {
                  setMode(MODE.UNSTAKE);
                  if (userStakeInfo?.stakedAmount) {
                    setAmount(Number(userStakeInfo?.stakedAmount));
                  }
                },
                selected: mode === MODE.UNSTAKE,
              },
              {
                text: 'Claiming',
                onSelection: () => {
                  setMode(MODE.CLAIM);
                  if (usdcUserRewards) {
                    setAmount(Number(usdcUserRewards.toFixed(2)));
                  }
                },
                selected: mode === MODE.CLAIM,
              },
            ]}
          />
        </div>
        <div className="group-16-4">
          {!((Number(userStakeInfo?.stakedAmount) ?? 0) > 0) ||
          !userStakeInfo?.unlockTime ||
          userStakeInfo?.unlockTime === 0 ||
          Number(userStakeInfo?.unlockTime) > date ? (
            <div className="time-info-mobile oxanium-normal-white-16px">No funds staked</div>
          ) : (
            <div className="time-info-mobile oxanium-bold-white-18px">
              Your Unlock Time {unlockDateMemo?.toLocaleDateString()} {unlockDateMemo?.toLocaleTimeString()}
            </div>
          )}
          <div className="amount-to-stake-6 oxanium-normal-white-16px">Amount to Stake</div>
          <StakingInput disabled={mode != MODE.STAKE} value={amount} onChange={(v) => setAmount(Number(v))} />
          <div className="available-balance-200000-dexi-6 oxanium-normal-white-13px group-16-4-1">
            <span>Available Balance:</span> <span>{`${ownBalance ?? '--'} ${stakeType}`}</span>
          </div>
          <div className="available-balance-200000-dexi-6 oxanium-normal-white-13px group-16-4-1">
            <span>Staked Balance:</span> <span>{`${userStakeInfo?.stakedAmount ?? '--'} ${stakeType}`}</span>
          </div>
        </div>
        {mode === MODE.STAKE ? (
          approveStatus !== ApprovalState.APPROVED ? (
            <StakingMainButton
              onClick={() => {
                stakeType === 'USDC' ? approveUsdc() : stakeType === 'DEXI' ? approveDexi() : approveDGV();
              }}
            >
              Approve {stakeType}
            </StakingMainButton>
          ) : (
            <>
              <Modal title="Stake Confirm" open={isModalOpen} onOk={handleOkDeposit} onCancel={handleCancel}>
                <p>
                  {stakeType === 'DGV'
                    ? `By Staking DGV you will be issued DVT tokens. You will be able to use DVT to vote on governance proposals. You will be required to give back your DVT tokens to unstake your DGV.`
                    : stakeType === 'USDC'
                    ? 'In our continuing effort to stay current with the US regulatory environment, and given recent actions taken by the SEC against Kraken, Dexioprotocol had opted to end any NEW staking of USDC. However, in light of new information and our desire to offer the best products and services to our community, we have decided to reopen the USDC staking program. All USDC staking APRs have been adjusted up as well and that impacts any currently staked USDC retroactively. Please review the current staking APRs for each of the 4 terms periods and send us a message at support@dexioprotocol.com or join our Discord or Telegram if you have any questions.'
                    : 'Your assets will be locked for the duration of the staking lock. You will be able to claim your rewards and your assets only after the lock period ends.'}
                </p>
              </Modal>
              <StakingMainButton
                onClick={() => {
                  showModal();
                }}
              >
                Stake {stakeType}
              </StakingMainButton>
            </>
          )
        ) : mode === MODE.UNSTAKE ? (
          stakeType === 'DGV' && approveDVTStatus !== ApprovalState.APPROVED ? (
            <StakingMainButton
              onClick={() => {
                approveDVT();
              }}
            >
              Approve DVT
            </StakingMainButton>
          ) : (
            <StakingMainButton
              onClick={() => {
                if (library) {
                  withdraw(contractName, library);
                }
              }}
              disabled={
                unlockDateMemo == null || unlockDateMemo.getUTCSeconds() > date || unlockDateMemo.getUTCSeconds() == 0
              }
            >
              Unstake {stakeType}
            </StakingMainButton>
          )
        ) : (
          <>
            <Modal title="Claim" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
              <p>
                {`By "claiming" your rewards you will only be issued the rewards for this period and your original staked
                assets will be staked again. If you wish to receive all of your assets including the original
                staked amount, please use the "Unstake" function.`}
              </p>
            </Modal>
            <StakingMainButton
              onClick={() => {
                showModal();
              }}
              disabled={
                unlockDateMemo == null || unlockDateMemo.getUTCSeconds() > date || unlockDateMemo.getUTCSeconds() == 0
              }
            >
              Claim
            </StakingMainButton>
          </>
        )}
        <StakingSelect />
      </div>
    </div>
  );
};
