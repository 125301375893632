import { NetworkConnector } from '@web3-react/network-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { Web3ReactHooks, initializeConnector } from '@web3-react/core';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { Network } from '@web3-react/network';
import { MetaMask } from '@web3-react/metamask';

export const [injectedConnector, injectedConnectorHooks] = initializeConnector<MetaMask>(
  (actions) =>
    new MetaMask({
      actions,
    }),
);

export const [networkConnector, networkConnettorHooks] = initializeConnector<Network>(
  (actions) =>
    new Network({
      actions,
      urlMap: { 137: process.env.REACT_APP_CHAIN_URL ?? '' },
      defaultChainId: 137,
    }),
);

export const [walletConnector, hooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: '8705afdec3ce6cd9faf9ee4050cd95db',
        chains: [137],
        showQrModal: true,
      },
    }),
);

export const allconnectorsAndHooks: [MetaMask | WalletConnectV2 | Network, Web3ReactHooks][] = [
  [injectedConnector, injectedConnectorHooks],
  [networkConnector, networkConnettorHooks],
  [walletConnector, hooks],
];
