import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Interface } from 'ethers/lib/utils';
import { UserInfoUsdcStake } from 'services/dexiStakeService';
import { getDisplayBalance } from 'helpers/tokenUtils';
import { BigNumber } from 'ethers';

interface IUserInfoUsdcStake {
  user: string;
  stakedAmount: Number;
  rewardAmount: Number;
  lastOperation: Number;
  unlockTime: Number;
}

interface ISingleContractData {
  contractName?: string;
  lockPeriodFixed?: number;
  stakeType?: string;
  total?: number;
  awarded?: number;
  apyRate?: string;
  stakers?: number;
  tokenName?: string;
  ownBalance?: string;
  lockPeriod?: number;
  dexiUserRewards?: Number;
  usdcUserRewards?: Number;
  userStakeInfo?: IUserInfoUsdcStake;
}
export interface IContractState {
  activeContract: string;
  stakeType: string;
  list: Record<string, ISingleContractData>;
}

const initialState: IContractState = {
  activeContract: 'DEXIUSDCSTAKE30',
  stakeType: 'USDC',
  list: {
    DEXIUSDCSTAKE30: { contractName: 'DEXIUSDCSTAKE30', stakeType: 'USDC', lockPeriodFixed: 30 * 24 * 60 * 60 },
    DEXIUSDCSTAKE60: { contractName: 'DEXIUSDCSTAKE60', stakeType: 'USDC', lockPeriodFixed: 60 * 24 * 60 * 60 },
    DEXIUSDCSTAKE90: { contractName: 'DEXIUSDCSTAKE90', stakeType: 'USDC', lockPeriodFixed: 90 * 24 * 60 * 60 },
    DEXIUSDCSTAKE180: { contractName: 'DEXIUSDCSTAKE180', stakeType: 'USDC', lockPeriodFixed: 180 * 24 * 60 * 60 },
    DEXIDEXISTAKE30: { contractName: 'DEXIDEXISTAKE30', stakeType: 'DEXI', lockPeriodFixed: 30 * 24 * 60 * 60 },
    DEXIDEXISTAKE60: { contractName: 'DEXIDEXISTAKE60', stakeType: 'DEXI', lockPeriodFixed: 60 * 24 * 60 * 60 },
    DEXIDEXISTAKE90: { contractName: 'DEXIDEXISTAKE90', stakeType: 'DEXI', lockPeriodFixed: 90 * 24 * 60 * 60 },
    DEXIDEXISTAKE180: { contractName: 'DEXIDEXISTAKE180', stakeType: 'DEXI', lockPeriodFixed: 180 * 24 * 60 * 60 },

    DGVDGVSTAKE30: { contractName: 'DGVDGVSTAKE30', stakeType: 'DGV', lockPeriodFixed: 30 * 24 * 60 * 60 },
    DGVDGVSTAKE60: { contractName: 'DGVDGVSTAKE60', stakeType: 'DGV', lockPeriodFixed: 60 * 24 * 60 * 60 },
    DGVDGVSTAKE90: { contractName: 'DGVDGVSTAKE90', stakeType: 'DGV', lockPeriodFixed: 90 * 24 * 60 * 60 },
    DGVDGVSTAKE180: { contractName: 'DGVDGVSTAKE180', stakeType: 'DGV', lockPeriodFixed: 180 * 24 * 60 * 60 },
  },
};

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setContract: (state, action: PayloadAction<string>) => {
      state.activeContract = action.payload;
      console.log('Updated ', action);
    },
    setStakeType: (state, action: PayloadAction<string>) => {
      state.stakeType = action.payload;
      state.activeContract =
        action.payload === 'USDC' ? 'DEXIUSDCSTAKE30' : action.payload === 'DEXI' ? 'DEXIDEXISTAKE30' : 'DGVDGVSTAKE30';
      console.log('Updated ', action);
    },
    setContractData: (state, action: PayloadAction<ISingleContractData>) => {
      const key = action.payload.contractName ? action.payload.contractName : state.activeContract;
      state.list[key] = { ...state.list[key], ...action.payload };
      state.list[key].contractName = key;
      console.log('Updated ', key, action);
    },
    setStakers: (state, action: PayloadAction<ISingleContractData>) => {
      const key = action.payload.contractName ? action.payload.contractName : state.activeContract;
      state.list[key].stakers = action.payload.stakers;
    },
    setApr: (state, action: PayloadAction<ISingleContractData>) => {
      const key = action.payload.contractName ? action.payload.contractName : state.activeContract;
      state.list[key].apyRate = action.payload.apyRate;
      console.log('Updated ', key, action);
    },
    setGlobalRewards: (state, action: PayloadAction<ISingleContractData>) => {
      const key = action.payload.contractName ? action.payload.contractName : state.activeContract;
      state.list[key].awarded = action.payload.awarded;
      console.log('Updated ', key, action);
    },
    setStakeFunds: (state, action: PayloadAction<ISingleContractData>) => {
      const key = action.payload.contractName ? action.payload.contractName : state.activeContract;
      state.list[key].total = action.payload.total;
      console.log('Updated ', key, action);
    },
    setLockPeriod: (state, action: PayloadAction<ISingleContractData>) => {
      const key = action.payload.contractName ? action.payload.contractName : state.activeContract;
      state.list[key].lockPeriod = action.payload.lockPeriod;
      console.log('Updated ', key, action);
    },
    setDexiUserRewards: (state, action: PayloadAction<ISingleContractData>) => {
      const key = action.payload.contractName ? action.payload.contractName : state.activeContract;
      state.list[key].dexiUserRewards = action.payload.dexiUserRewards;
      console.log('Updated ', key, action);
    },
    setUsdcUserRewards: (state, action: PayloadAction<ISingleContractData>) => {
      const key = action.payload.contractName ? action.payload.contractName : state.activeContract;
      state.list[key].usdcUserRewards = action.payload.usdcUserRewards;
      console.log('Updated ', key, action);
    },
    setUserStakeInfo: (state, action: PayloadAction<ISingleContractData>) => {
      const key = action.payload.contractName ? action.payload.contractName : state.activeContract;
      state.list[key].userStakeInfo = action.payload.userStakeInfo;
      console.log('Updated ', key, action);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setContract,
  setContractData,
  setApr,
  setGlobalRewards,
  setLockPeriod,
  setStakeFunds,
  setStakers,
  setDexiUserRewards,
  setUsdcUserRewards,
  setUserStakeInfo,
  setStakeType,
} = contractSlice.actions;

export default contractSlice.reducer;
