import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useAppStore = () => {
  const { activeContract } = useSelector((state: RootState) => state.contract);
  const stakeType = useSelector((state: RootState) => state.contract.stakeType);
  const {
    apyRate,
    awarded,
    contractName,
    lockPeriod,
    lockPeriodFixed,
    ownBalance,
    stakers,
    tokenName,
    total,
    dexiUserRewards,
    usdcUserRewards,
    userStakeInfo,
  } = useSelector((state: RootState) => state.contract.list[state.contract.activeContract]);
  return {
    apyRate,
    awarded,
    contractName: contractName ? contractName : '',
    stakeType: stakeType ? stakeType : '',
    lockPeriod,
    lockPeriodFixed,
    ownBalance,
    stakers,
    tokenName,
    total,
    dexiUserRewards,
    usdcUserRewards,
    userStakeInfo,
    activeContract,
  };
};
