import React, { FC, ReactElement, ReactNode } from "react";
import { useMediaQuery } from "usehooks-ts";
import { path1019, path1080 } from "./ui";

interface ISingleCard {
  text?: string | number;
  children?: ReactElement;
}

const SingleCard: FC<ISingleCard> = (props) => {
  const { text, children } = props;

  const isDesktop = useMediaQuery("(min-width:1024px)");

  if (isDesktop) {
    return (
      <div className="x1">
        <div className="group">
          <div className="overlap-group">
            <div className="rectangle-18"></div>
            <div className="rectangle-13"></div>
            <div className="rectangle-368"></div>
            <img className="path-10" src={path1019} alt="Path 10" />
            <div className="ellipse-1-1"></div>
            <div className="ellipse-2"></div>
            <h1 className="text-1 oxanium-semi-bold-white-37px">{text}</h1>
          </div>
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className="group-container-21">
      <div className="overlap-group-33">
        <div className="rectangle-18-22"></div>
        <div className="rectangle-13-16"></div>
        <div className="rectangle-368-4"></div>
        <img className="path-10-4" src={path1080} alt="Path 10" />
        <div className="ellipse-1-26"></div>
        <div className="ellipse-2-29"></div>
        <div
          style={{
            width: "100%",
            left: "0px",
            textAlign: "center",
          }}
          className="text-13 oxanium-semi-bold-white-37px"
        >
          {text}
        </div>
      </div>
      {children}
    </div>
  );
};

export { SingleCard };
