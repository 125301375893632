import React from "react";
import "./Group50.css";
import logo from "../../assets/img/peolpe-1@1x.png";
import { useMediaQuery } from "usehooks-ts";

const Group50 = (props: { className?: string; text?: string }) => {
  const { text } = props;
  const isMobile = useMediaQuery("(max-width:1023px)");
  return (
    <div className={`group-50 ${isMobile ? "group-50-4" : ""}`}>
      <img className="peolpe" src={logo} alt="peolpe" />
      {text && <div className="stakers oxanium-normal-white-21px">{text}</div>}
    </div>
  );
};

export default Group50;
